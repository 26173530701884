import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { IconsPlugin } from "bootstrap-vue";
import { BootstrapVue } from 'bootstrap-vue'
// import "@/scss/colors.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
import "@/scss/style.scss";
import store from './store'
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
