<template lang="pug">
div.body
    b-alert(class="w-100" style="position: fixed; top: 1%; font-size: 1rem; z-index: 5; margin: 0;" :variant="alert.color" :show="alert.dismissCountDown" fade @dismissed="alert.dismissCountDown=0" @dismiss-count-down="callAlert")
      | {{ alert.alertMessage }}

    ModalConfirmation(:id="modalListId[0]" title="Save Confirmation" message="Are you sure you want to save this caregiver information?" btn-ok-text="Save" :is-show-spinner="isShowSpinner" @modal-cancel="modalCancelActivity" @modal-confirm="modalConfirmActivity")

    ModalConfirmation(:id="modalListId[1]" title="Populate Caregiver Info" message="Some caregiver information are already filled, do you wish to continue from there?" btn-ok-text="Yes" @modal-cancel="modalCancelActivity" @modal-confirm="modalConfirmActivity")

    b-modal(:id="modalCara" title="Sign Up for CARA?" size="xl" hide-footer centered scrollable)
      div.text-center
        img(src="../assets/cara_background.jpg" style="max-width: 70%; height: auto;")
        h6.my-2(style="color: rgb(78, 37, 101);") CARA card is a revised version of the original Safe Return Card, originally initiative by National Council of Social Service, used by persons living with dementia. It functions like Safe Return Card, but with added features and benefits. This card is free. Please click “continue”if you wish to join CARA free membership. Otherwise click “skip” if you do not wish to join CARA membership. Currently the Family Of Wisdom program is using the Cara card for digital check-in and check-out as a form of attendance marking, so caregivers are strongly advised to join the CARA membership to ease the checkin and check out progress, as part of the digitalisation process. Thank you!”
        a(href="https://cara.sg" target="_blank" style="color: black;font-weight: bold;") Click here to know more about CARA!
        .row.my-3
          .col.text-center.m-1
            b-btn.optionYesBtn(@click="AcceptCaraAndSubmit") Continue
          .col.text-center.m-1
            b-btn.optionCancelBtn(@click="RejectCaraAndSubmit") Skip
          

    header-component

    <!-- b-button.bg-success(v-if="isShowSaveDraftCaregiver" style="display: block; position: fixed; right: 0.5%;" @click="openModal(0)")
      | Save Caregiver Draft
    b-button.bg-success(v-else style="display: none;")
      | Save Caregiver Draft -->
    div.formBody.d-flex.justify-content-center(v-if="submitting")
      b-spinner(variant="primary")
    div.formBody(v-show="!submitting")
      h2 Caregiver Registration
      .margin-container
          b-card.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
              template(#header)
                h5.mb-0.py-1 Primary Caregiver Information (Mandatory)
              .row
                  .col-sm
                      label.form-label(for="exampleFormControlInput1") Enter Full Name
                      input#exampleFormControlInput1.form-control(type="text" v-model="caregiver.primary.fullName")
                      
                  .col-sm
                      label.form-label(for="exampleFormControlInput1") Contact Number (Home)
                      input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.primary.contactHome" )
              .row.mt-3
                      .col-sm
                          label.form-label(for="exampleFormControlInput1") Email
                          input#exampleFormControlInput1.form-control(type="email" v-model="caregiver.primary.email")
                      .col-sm
                          label.form-label(for="exampleFormControlInput1") Contact Number Mobile
                          input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.primary.contactMobile")
              .row.mt-3
                      .col-sm
                          label.form-label(for="exampleFormControlInput1") Relationship
                          v-select(:options="relationOptions" taggable="" v-model="caregiver.primary.relationship")
                      .col-sm(v-show="caregiver.primary.relationship==='Others'")
                          label.form-label(for="exampleFormControlInput1") Relationship Others
                          input#exampleFormControlInput1.form-control(v-model="caregiver.primary.relationshipOthers")
              
                      
              .row.mt-3 
                      .col-sm
                            label.form-label(for="exampleFormControlInput1") Living with Applicant
                            v-select(:options="yesNoOptions" v-model="caregiver.primary.livingWithApplicant" :reduce="d => d.value")
                      .col-sm
                          label.form-label(for="exampleFormControlInput1") Is this person Emergency Contact?
                          v-select(:options="yesNoOptions" v-model="caregiver.primary.emergencyContact" :reduce="d => d.value")
                      
              .row.mt-3 
          .row.mt-4
            .col-sm.my-2
              b-card.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Secondary Caregiver Information (Optional)
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Enter Full Name
                      input#exampleFormControlInput1.form-control(type="text" v-model="caregiver.secondaryFullName")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Contact Number (Home)
                      input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.secondaryContactHome")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Email
                      input#exampleFormControlInput1.form-control(type="email" v-model="caregiver.secondaryEmail")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Contact Number Mobile
                      input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.secondaryContactMobile")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Relationship
                      v-select(:options="relationOptions" taggable="" v-model="caregiver.secondaryRelationship")
                  div(v-show="caregiver.secondaryRelationship==='Others'")
                      label.form-label(for="exampleFormControlInput1") Relationship Others
                      input#exampleFormControlInput1.form-control(v-model="caregiver.secondaryRelationshipOthers")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Living with Applicant
                      v-select(:options="yesNoOptions" v-model="caregiver.secondaryLivingWithApplicant" :reduce="d => d.value")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Emergency Contact
                      v-select(:options="yesNoOptions" v-model="caregiver.secondaryEmergencyContact" :reduce="d => d.value")
            .col-sm.my-2
              b-card.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Other Caregiver Information (Optional)
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Enter Full Name
                      input#exampleFormControlInput1.form-control(type="text" v-model="caregiver.otherFullName")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Contact Number (Home)
                      input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.otherContactHome")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Email
                      input#exampleFormControlInput1.form-control(type="email" v-model="caregiver.otherEmail")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Contact Number Mobile
                      input#exampleFormControlInput1.form-control(type="number" v-model="caregiver.otherContactMobile")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Relationship
                      v-select(:options="relationOptions" taggable="" v-model="caregiver.otherRelationship")
                  div.my-2(v-show="caregiver.otherRelationship==='Others'")
                      label.form-label(for="exampleFormControlInput1") Relationship Others
                      input#exampleFormControlInput1.form-control(v-model="caregiver.otherRelationshipOthers")
                  div.my-2
                        label.form-label(for="exampleFormControlInput1") Living with Applicant
                        v-select(:options="yesNoOptions" v-model="caregiver.otherLivingWithApplicant" :reduce="d => d.value")
                  div.my-2
                      label.form-label(for="exampleFormControlInput1") Emergency Contact
                      v-select(:options="yesNoOptions" v-model="caregiver.otherEmergencyContact" :reduce="d => d.value")
          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
              template(#header)
                h5.mb-0.py-1 Please direct all correspondence (include invoices and receipts) to:
              .row.mt-2 
                  .col-sm
                          label.form-label(for="exampleFormControlInput1") Select Recipient*
                          b-form-select.select(v-model="caregiver.recipient")
                              b-form-select-option(v-if="caregiver.primary.fullName!==''" :value="0") {{caregiver.primary.fullName}}
                              b-form-select-option(v-if="caregiver.secondaryFullName!==''" :value="1") {{caregiver.secondaryFullName}}
                              b-form-select-option(v-if="caregiver.otherFullName!==''" :value="2") {{caregiver.otherFullName}}
                  .col-sm
                            label.form-label(for="exampleFormControlInput1") Select Mode of Delivery*
                            b-form-select.select(:options="modeOfTransportOptions" v-model="caregiver.modeOfDelivery")
              .row.mt-2(v-show="caregiver.modeOfDelivery=== 1")
                  .com-sm
                      label.form-label(for="exampleFormControlInput1") Enter Address*
                      textarea#exampleFormControlInput1.form-control(type="text" v-model="caregiver.modeOfDeliveryAddress")

          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
              template(#header)
                h5.mb-0.py-1 
                  b Zarit Screen Measure of Family Caregiver Burden (负 担 问 卷)
                .row.mt-2
                    p The questions below reflect how you, as caregiver, sometimes feel when you are taking care of your loved one with dementia.  After each statement, rate the number that best describes how often you feel that way three months ago and now.  There is no right or wrong answer. We ask you to answer these questions periodically to measure your level of stress and how it changes.
                    | 以 下 问 题 反 映 当 你 照 顾 你 亲 人 时 的 感 受 。请 在 每 道 问 题 的 右 边 填写号码 出 最 能 形 容 你 的 感 受 之 选 项, 三个月前和现在。没 有 任 何 答 案 是 对 或 错 的。我们要求您回答这些问题来恒量你照顾你的失智症的家属的压力程度。 
              .row.my-2
                  .col-sm
                      p.oneLiner RATE THE RESPONSE THAT BEST DESCRIBE HOW YOU FEEL 我们想了解你的感受
              .row.mt-2 
                  .col-sm
                      p 1.	Do you feel that because of the time you spend with your relative that you don't have enough time for yourself?* 
                      | 你是 否 觉 得 因 为 花 了 时 间 在 你 亲 人 身 上 而 造 成 自 己 没 有 足 够 的 时 间？*
                  
                  .col-sm
                      b-form-select.select.middle(:options="zaritOptions" v-model="caregiver.zaritQuestionOne")
              
              .row.mt-5
                  .col-sm
                      p 2.	Do you feel stressed between caring for your relative and trying to meet other responsibilities for your family or work?*
                      | 你 是 否 因 为 要 照 料 你 亲 人 ，同 时 又 要 应 付 家 庭 或 工 作 上 的 其 他 责 任 而 觉 得 有 压力 ？*
                  .col-sm
                      b-form-select.select.middle(:options="zaritOptions" v-model="caregiver.zaritQuestionTwo")
              .row.mt-5
                  .col-sm
                      p 3.	Do you feel strained when you are around your relative?*
                      | 当 你 和 你 亲 人 在 一 起 时 ，你 是 否 感 到 不 自在？*
                  .col-sm
                      b-form-select.select.middle(:options="zaritOptions" v-model="caregiver.zaritQuestionThree")
              .row.mt-5
                  .col-sm
                      p 4.	Do you feel uncertain about what to do about your relative?*  
                      | 你 是 否 觉 得 不 能 确 定 要 为 你 亲 人 做 什 么？*
                  .col-sm
                      b-form-select.select.middle(:options="zaritOptions" v-model="caregiver.zaritQuestionFour")

          
          //-FOW Consent form
          //- hr.mb-5
          //- h1.mt-5 TERMS AND CONDITIONS OF ADMISSION
          //- p.mt-4 Fees are charged based on the number of sessions client has attended in a month. Caregiver willreceive the invoice at the beginning of next month.

          //- h4.mt-4.text-left Deposit
          //- p.text-justify A deposit of one-month refundable deposit equivalent to the number of sessions intend to attend within one month, will need to be paid upon admission. This will be refunded when the client is discharged from the service and no dues are owing.

          //- h4.mt-4.text-left Discharge
          //- p.text-justify Clients who have been absent for more than 2 months without official notice and/or have defaulted payment for services provided for three (3) months consecutively shall be discharged from the Centre's list. The Centre reserves the right to terminate its services to any client found unsuitable for its programme (please refer to Discharge Policy). 

          //- h4.mt-4.text-left Emergency Procedures
          //- p.text-justify Should an emergency arise (such as falls, injuries, changes in the client’s condition while at the Centre, medical and fire) and the client needs to be sent to the hospital, Centre would send the client to the hospital and contact the main caregiver or second caregiver (if the main caregiver could not be reached). Client may return to the Centre when he/she has fully recovered.

          //- h4.mt-4.text-left Repair/Replacement
          //- p.text-justify If the client causes damage to any of the Centre's equipment or facilities, the next-of-kin shall bear the cost of repair/replacement.

          //- h4.mt-4.text-left Liabilities
          //- p.text-justify The client should not bring valuables, eg. Jewelry or large sum of money when attending the Centre as the Centre will not be held liable for any loss. The client and/or his next-of-kin shall indemnify the Centre and its staff against all liabilities whatsoever and howsoever arising, which the client may suffer or incur because of the client’s attendance or conduct at the Centre. The Centre shall not be held responsible or liable for the safety of any client who willfully refuses to abide by the rules and regulations of the Centre.

          //- h4.mt-4.text-left Feedback Procedures
          //- p.text-justify The Centre believes in upholding the quality of care and service and welcomes feedback from caregivers. It could be done through our DSG Helpline or Centre Manager.

          //- hr

          //- .row.mt-5(style="border: 2px solid ") 
          //-   h6.mt-4.mb-4 Consent for Emergency Treatment – in the event if family caregiver is not around should an emergency occurred to his/her loved ones at the Centre
          
          //- .row.mt-5(style="text-align:justify;line-height:5vh")
          //-   p I, #[input.form-control(type="text" v-model="fwf")](NRIC name), give my consent to Family of Wisdom (Bendemeer), to seek medical attention (including hospital) for my #[input.form-control(type="text" v-model="fwf")] (relationship),#[input.form-control(type="text" v-model="fwf")](NRIC name of client) in the event of an emergency. 
          //-   p.mt-4 I agree to be responsible for all expenses thus incurred, including transportation and medical fees.
          //-   p.mt-4 In an emergency, please contact:
          
          //- .row 
          //-  .col      
          //-   p.text-left Name 1: #[input.form-control(type="text" v-model="fwf")]
          //-  .col 
          //-   p.text-left Relationship to Client: #[input.form-control(type="text" v-model="fwf")]
          //- .row  
          //-   .col
          //-     P.text-left Mobile: #[input.form-control(type="text" v-model="fwf")]
          //-   .col  
          //-     P.text-left Home:#[input.form-control(type="text" v-model="fwf")]
          //-   .col  
          //-     P.text-left Office: #[input.form-control(type="text" v-model="fwf")]
            

          
          //- .row.mt-5 
          //-  .col      
          //-   p.text-left Name 2: #[input.form-control(type="text" v-model="fwf")]
          //-  .col 
          //-   p.text-left Relationship to Client: #[input.form-control(type="text" v-model="fwf")]
          //- .row  
          //-   .col
          //-     P.text-left Mobile: #[input.form-control(type="text" v-model="fwf")]
          //-   .col  
          //-     P.text-left Home:#[input.form-control(type="text" v-model="fwf")]
          //-   .col  
          //-     P.text-left Office: #[input.form-control(type="text" v-model="fwf")]
            
          //- .row.mt-5(style="border: 2px solid ") 
          //-   h6.mt-4.mb-4 Consent for:
          //-   h6.text-left.mx-4 (i) Sharing of Information
          //-   h6.text-left.mx-4 (ii) Photos, Videos and Films
          //-   h6.text-left.mx-4.mb-4 (iii) CARA Membership (refer to application form included in the registration pack)

          //- h5.text-left.mt-5 (i) Sharing of Information
          //- p(style="text-align:justify;line-height:5vh").text-justify.mt-4 I,#[input.form-control(type="text" v-model="fwf")] (NRIC Name), #[input.form-control(type="text" v-model="fwf")] (NRIC No.) give my consent to Family of Wisdom (Bendemeer) to share and discuss information about my #[input.form-control(type="text" v-model="fwf")](relationship), #[input.form-control(type="text" v-model="fwf")](NRIC name of client) with professionals and staff members of organizations in healthcare, community and social services for his/her health and welfare. I trust that the Centre will treat this information with due respect.
            
          //- h5.text-left.mt-5 (ii) Photos, Videos and Films
          //- p(style="text-align:justify;line-height:5vh").text-justify.mt-4 I willingly authorize / do not authorize* the Centre to use any films, video, photos taken whilst attending the Centre’s programmes. This may be for the purpose of educating others on understanding and managing a person with dementia. I also give / do not give* my permission to the publication of such photos or video for the purpose of PUBLIC education, with the understanding that he/she may be identified by his/her first name.
            
          
          //- hr

          //- h1.mt-5 DISCHARGE POLICY 

          //- p.mt-5 The client can be discharged for the following reasons as determined by the Centre Manager:

          //- section.text-justify.mt-5
          //-   p.mt-3 1. The client has defaulted attendance or has not been able to attend the Centre for a continuous period of more than two (2) months, or up to three (3) months (with valid reasons).

          //-   p.mt-4 2. Client has developed unstable medical conditions requiring close medical monitoring, end stage diseases, unmanageable behavioural issues and/or uncontrolled mental illness despite treatment

          //-   p.mt-4 3. Client's physical function has deteriorated such that his/her care needs cannot be adequately and safely be provided for in the centre;

          //-   p.mt-4 4. The client's condition has deteriorated such that it poses harm to self/other clients/staff in the Centre.

          //-   p.mt-4 5. The client has defaulted payment for services provided for a continuous period of more than three (3)
          
          //- hr
          //- h1.mt-5 SERVICE AGREEMENT

          //- .row.mt-5
          //-     .col-sm.text-left
          //-       p NRIC Name of Client:
          //-     .col-sm
          //-       input.form-control(type="text" v-model="fwf")
          
          //- .row.mt-3
          //-     .col-sm.text-left
          //-       p NRIC No. of Client:
          //-     .col-sm
          //-       input.form-control(type="text" v-model="fwf")
          
          //- .row.mt-3
          //-     .col-sm.text-left
          //-       p Services Provided:
          //-     .col-sm
          //-       p Family of Wisdom (Enrichment) Programme:
          
          //- .row.mt-3
          //-     .col-sm.text-left
          //-       p Date of Commencement:
          //-     .col-sm
          //-       input.form-control(type="text" v-model="fwf")
                
          //- .row.mt-3
          //-     .col-sm.text-left
          //-       p Session Enrolled
          //-     .col-sm
          //-       p Mon / Tue / Wed / Thu / Fri (AM/PM)
          
          //- .row.mt-3
          //-     .col-sm.text-left
          //-       p Fee charged per session (before GST): $
          //-     .col-sm
          //-       input#exampleFormControlInput1.form-control(type="text" v-model="fwf")
                

          //- .row.mt-5(style="text-align:justify;line-height:5vh")
          //-   p I, #[input.form-control(type="text" v-model="fwf")] (NRIC Name), confirm that I am the #[input.form-control(type="text" v-model="fwf")] (relationship) of #[input.form-control(type="text" v-model="fwf")] (NRIC Name of client),#[input.form-control(type="text" v-model="fwf")] (NRIC No.).
            
          //- hr  
          //- section.mt-5(style="text-align:left;")
          //-   p I hereby declare that I have understood and agree to abide by the:

          //-   ul
          //-       li Service Agreement
          //-       li Terms and Conditions of Admission
          //-       li Consent for Emergency Treatment
          //-       li Consent for Sharing of Information/Photos/Videos & Films/CARA Membership
          //-       li Discharge Policy 
                
          //-   p of Family of Wisdom (Bendemeer)

            
          //-   .row.mt-5
          //-     .col 
          //-       .row.mt-5
          //-             VueSignatureCanvas.gap(ref="handWrite" :canvasProps="{class: 'sig-canvas'}")
          //-       .row.mt-2
          //-             p --- Sign here ---
          //-     .col
          //-       .col-sm.mt-3
          //-         p Contact Number:
          //-       .col-sm
          //-         input.form-control(type="text" v-model="fwf")

          //-       .row.mt-5
          //-       .col-sm 
          //-         p Date:
          //-       .col-sm
          //-         input.form-control(type="text" v-model="fwf")

          //-CIP Consent form
          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
            template(#header)
              h3.my-2 TERMS AND CONDITIONS OF ADMISSION
              h6.my-2 Fees are charged based on the number of sessions client has attended in a month. Caregiver will receive the invoice at the beginning of next month.
            
            h4.my-2.text-left Deposit
            p.text-justify A deposit of one-month refundable deposit equivalent to the number of sessions intend to attend within one month, will need to be paid upon admission. This will be refunded when the client is discharged from the service and no dues are owing.

            h4.my-2.text-left Discharge
            p.text-justify Clients who have been absent for more than 2 months without official notice and/or have defaulted payment for services provided for three (3) months consecutively shall be discharged from the Centre's list. The Centre reserves the right to terminate its services to any client found unsuitable for its programme (please refer to Discharge Policy). 

            h4.my-2.text-left Emergency Procedures
            p.text-justify The next-of-kin or caregiver must make available contact numbers to facilitate timely communication in cases of emergency. In the event of an emergency (such as falls, injuries, change in the client’s condition while at the centre), centre staff will offer first aid to the client and will inform the next-of-kin or caregiver about the situation. The centre may arrange for the client to be sent to the nearest medical facility after informing the next-of-kin or caregiver and call for ambulance services, if necessary. Should the next-of-kin or caregiver not be contactable during the emergency, the centre shall decide on the appropriate course of action. Client and / or the next-of-kin or caregiver undertakes full responsibility for all the care and medical costs incurred in responding to the emergency.

            h4.my-2.text-left Repair/Replacement
            p.text-justify If the client causes damage to any of the Centre's equipment or facilities, the next-of-kin shall bear the cost of repair/replacement.

            h4.my-2.text-left Liabilities
            p.text-justify The client and his / her next-of-kin or caregiver must at all times be responsible for his / her own personal property and belongings. The client is reminded NOT to bring valuables such as jewellery or significant sums of money when attending the session. The Centre will not be held responsible for any loss of any property, personal or otherwise, brought to the premises and shall not be held liable for them.
            p.text-justify.mt-3 The client and / or the next-of-kin shall indemnify the Centre staff against all liabilities whatsoever and howsoever arising, which the client may suffer or incur because of the client’s attendance or conduct at the Centre or while the client is being transported to and from the Centre by the Centre’s van or the Centre’s appointed vendor’s van.

            h4.my-2.text-left Respect, Courtesy and Dignity
            p.text-justify The Centre staff and volunteers have the right to be treated with dignity and respect at all times. The next-of-kin or caregiver and the client’s representatives shall give full support and cooperation to the Centre in all matters that promote the overall respect of other clients, staff and management, and the overall harmony and peace of the Centre.

            h4.my-2.text-left Feedback Procedures
            p.text-justify The Centre value the feedback from clients for service improvement. The next-of-kin or caregiver may contact Family of Wisdom at 63895385 or email us at eunice.tan@dementia.org.sg for any feedback.

          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
            template(#header)
              h6.my-2 Consent for Emergency Treatment – in the event if family caregiver is not around should an emergency occurred to his/her loved ones at the Centre   
            .row.my-2(style="text-align:justify;line-height:5vh")
              p I, #[input.form-control(type="text" placeholder="Enter primary caregiver name" v-model="termAndConditionInput.caregiverName")](NRIC name), give my consent to Family of Wisdom (Bendemeer), to seek medical attention (including hospital) for my #[v-select(placeholder="Enter relationship with client" :options="relationOptions" v-model="termAndConditionInput.relationship")](relationship),#[input.form-control(type="text" placeholder="Enter client name" v-model="termAndConditionInput.clientName")](NRIC name of client) in the event of an emergency. 
              p.mt-4 I agree to be responsible for all expenses thus incurred, including transportation and medical fees.
              p.mt-4 In an emergency, please contact:
          
            .row 
              .col      
              p.text-left Name 1: #[input.form-control(type="text" placeholder="Enter name" v-model="termAndConditionInput.emergencyContact.personOne.name")]
              .col 
              p.text-left Relationship to Client: #[v-select(:options="relationOptions" taggable="" v-model="termAndConditionInput.emergencyContact.personOne.relationshipToClient")]
            .row  
              .col
                P.text-left Mobile: #[input.form-control(type="text" placeholder="Enter mobile no" v-model="termAndConditionInput.emergencyContact.personOne.mobileNo")]
              .col  
                P.text-left Home:#[input.form-control(type="text" placeholder="Enter home no" v-model="termAndConditionInput.emergencyContact.personOne.homeNo")]
              .col  
                P.text-left Office: #[input.form-control(type="text" placeholder="Enter office no" v-model="termAndConditionInput.emergencyContact.personOne.officeNo")]
            

          
            .row.mt-5 
              .col      
              p.text-left Name 2: #[input.form-control(type="text" placeholder="Enter name" v-model="termAndConditionInput.emergencyContact.personTwo.name")]
              .col 
              p.text-left Relationship to Client: #[v-select(:options="relationOptions" taggable="" v-model="termAndConditionInput.emergencyContact.personTwo.relationshipToClient")]
            .row  
              .col
                P.text-left Mobile: #[input.form-control(type="text" placeholder="Enter mobile no" v-model="termAndConditionInput.emergencyContact.personTwo.mobileNo")]
              .col  
                P.text-left Home:#[input.form-control(type="text" placeholder="Enter home no" v-model="termAndConditionInput.emergencyContact.personTwo.homeNo")]
              .col  
                P.text-left Office: #[input.form-control(type="text" placeholder="Enter office no" v-model="termAndConditionInput.emergencyContact.personTwo.officeNo")]
          
          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
            template(#header)
              h4.my-2 Consent for:
              h6.text-left.mx-4 (i) Sharing of Information
              h6.text-left.mx-4 (ii) Photos, Videos and Films
              h6.text-left.mx-4(iii) CARA Membership (refer to application form included in the registration pack)            

            h5.text-left.my-3 (i) Sharing of Information
            p(style="text-align:justify;line-height:5vh").text-justify.mt-4 I,#[input.form-control(type="text" placeholder="Enter primary caregiver name" v-model="termAndConditionInput.sharingInformation.caregiverName")] (NRIC Name), #[input.form-control(type="text" placeholder="Enter primary caregiver NRIC No" v-model="termAndConditionInput.sharingInformation.caregiverNRICNo")] (NRIC No.) give my consent to Family of Wisdom (Bendemeer) to share and discuss information about my #[v-select(:options="relationOptions" taggable="" v-model="termAndConditionInput.sharingInformation.relationship")](relationship), #[input.form-control(type="text" placeholder="Enter client name" v-model="termAndConditionInput.sharingInformation.clientName")](NRIC name of client) with professionals and staff members of organizations in healthcare, community and social services for his/her health and welfare. I trust that the Centre will treat this information with due respect.
              
            h5.text-left.mt-5 (ii) Photos, Videos and Films
            p(style="text-align:justify;line-height:3vh").text-justify.mt-4 I willingly authorize / do not authorize* the Centre to use any films, video, photos taken whilst attending the Centre’s programmes. This may be for the purpose of educating others on understanding and managing a person with dementia. I also give / do not give* my permission to the publication of such photos or video for the purpose of PUBLIC education, with the understanding that he/she may be identified by his/her first name.

          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
            template(#header)
              h3.my-2 CONSENT AND DECLARATION
            b-col.col-12.my-2(style="text-align: start !important;")
              span(style="font-weight: 600;") A. Sharing of Personal Data
            b-col.col-12(style="text-align: start !important;")
              p.mt-3.text-justify I consent to allow Family of Wisdom, and/or its authorised representatives to collect, use, process or disclose the personal data (including name, NRIC and medical records) about my
              b-form-group(label="Relationship")
                v-select(:options="relationOptions" taggable="" v-model="consentFormInput.relationship")
              p.mt-3.text-justify I consent to allow Family of Wisdom, and/or its authorised representatives to collect, use, process or disclose the personal data (including name, NRIC and medical records) about my 
              b-form-group(label="Client Name")
                b-form-input(placeholder="Enter client name" v-model="consentFormInput.clientName")
              p.mt-3 of the purpose of:
            b-col.col-12
              b-row.justify-content-end
                b-col.col-11(style="text-align: start !important;")
                  p.text-justify 1. Assessing our profiles and needs to develop and implement intervention plans;
                  p.text-justify 2. Referring me to another agency that may be better able to support me than Family of Wisdom as part of intervention work; and
                  p.text-justify 3. Reporting to public agencies (such as the Ministry of Health and the Agency for Integrated Care), as required by them for funding and/or statistical purposes, including carrying out research, analysis and development of CIP activities
            b-row
              b-col.col-12(style="text-align: start !important;")
                p.text-justify I trust that the Centre will undertake the necessary measures to keep this information safe and confidential with the PDPA guideline.
            b-row
              b-col.col-12.mt-3(style="text-align: start !important;")
                span(style="font-weight: 600;") B. Media and Publicity
              b-col.col-12
                b-row.mt-3.justify-content-end
                  b-col.col-11(style="text-align: start !important;")
                    p.text-justify 1. By entering into Family of Wisdom Centre, photographs of activity with clients’ presence could be taken during any for the session.
                    p.text-justify 2. Family of Wisdom is authorised to use any of these photographs with or without client’s name for any lawful purpose including, though not exhaustive, for purposes such as publicity, illustration and web content.
              b-col.col-12(style="text-align: start !important;")
                p.text-justify I willingly authorize / do not authorize* the Centre to use any films, video, photos taken whilst attending the sessions. This may be for the purpose of educating others on understanding and managing a person with dementia.
              b-col.col-12(style="text-align: start !important;")
                p.text-justify I also give / do not give* my permission to the publication of such photos or video for the purpose of #[span(style="font-weight: 600; text-decoration: underline;") PUBLIC] education, with the understanding that he/she may be identified by his/her first name.


          b-card.mt-4.border-dark.h-100.shadow(header-bg-variant="primary" header-text-variant="white")
            template(#header)
              h3.my-2 DISCHARGE POLICY 

            p.my-2 The client can be discharged for the following reasons as determined by the Centre Manager:

            section.text-justify.mt-2
              p.mt-4 1. The client has defaulted attendance or has not been able to attend the Centre for a continuous period of more than two (2) months, or up to three (3) months (with valid reasons).

              p.mt-4 2. Client has developed unstable medical conditions requiring close medical monitoring, end stage diseases, unmanageable behavioural issues and/or uncontrolled mental illness despite treatment

              p.mt-4 3. Client's physical function has deteriorated such that his/her care needs cannot be adequately and safely be provided for in the centre;

              p.mt-4 4. The client's condition has deteriorated such that it poses harm to self/other clients/staff in the Centre.

              p.mt-4 5. The client has defaulted payment for services provided for a continuous period of more than three (3)


          b-card.mt-4
            .row
              .col-sm
                div
                    VueSignatureCanvas.gap(ref="caregiverSignature" :canvasProps="{class: 'sig-canvas'}")
                    p --- Caregiver Sign here ---
                div
                    b-button(size="sm" variant="danger" @click="clearSignature") Clear Signature
              .col-sm
                div.my-3
                    p Contact Number:
                    input.form-control(type="text" placeholder="Enter contact number" v-model="finalInput.contactNo")
                div.my-3
                    p Date:
                    input.form-control(type="date" v-model="finalInput.dateSignature")

            

      b-btn.mb-5.mt-5.optionYesBtn(variant='primary' @click="showModalAndCaraPermission" :disabled="submitting || !caregiversDataValid") {{submitting ? 'Submitting... Please wait.' : 'Submit'}}



          //- b-link.btn.mt-3.mb-5#fixedButton 
          //-     b-icon(icon="save" font-scale="1")
          //-     | &nbsp;Save Draft

                    
                    



                


                    



</template>

<script>
import HeaderComponent from "../component/HeaderComponent.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from 'axios';
import VueSignatureCanvas from 'vue-signature-canvas';
import { z } from "zod";
import dayjs from "dayjs";
import ModalConfirmation from '../component/ModalConfirmation.vue';

export default {
  name: "FormCaregiver",
  components: {
    HeaderComponent,
    vSelect,
    VueSignatureCanvas,
    ModalConfirmation,
  },
  data() {
    return {
      termsCollapseState:true,
      clientId: '',
      isSubmitMidRegistration: false,
      isShowSaveDraftCaregiver: false,
      caregiverRank: [0, 1, 2],
      tempCaregiverData: null,
      tempModeDeliveryData: null,
      tempZaritData: null,
      modalListId: ["modal-caregiver-info-confirmation", "modal-caregiver-get-confirmation"],
      midRegistrationCaregiverState: {
        isCaregiverInDB: false,
      },
      isShowSpinner: false,
      alert: {
        color: "",
        colorOption: ["danger", "success"],
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: true,
        alertMessage: "",
      },
      caregiver: {
        caregiverForm: true,
        primary: {
          fullName: "",
          contactHome: "",
          email: "",
          contactMobile: "",
          relationship: "",
          relationshipOthers: "",
          livingWithApplicant: null,
          emergencyContact: null,
        },
        secondaryFullName: "",
        secondaryContactHome: "",
        secondaryEmail: "",
        secondaryContactMobile: "",
        secondaryRelationship: "",
        secondaryLivingWithApplicant: null,
        secondaryEmergencyContact: null,
        secondaryRelationshipOthers: "",

        otherFullName: "",
        otherContactHome: "",
        otherEmail: "",
        otherContactMobile: "",
        otherRelationship: "",
        otherLivingWithApplicant: null,
        otherEmergencyContact: null,
        otherRelationshipOthers: "",

        recipient: null,
        modeOfDelivery: "",
        modeOfDeliveryAddress: "",

        zaritQuestionOne: "",
        zaritQuestionTwo: "",
        zaritQuestionThree: "",
        zaritQuestionFour: ""
      },
      submitting: false,
      termAndConditionInput: {
        caregiverName: null,
        clientName: null,
        clientNric: null,
        relationship: null,
        emergencyContact: {
          personOne: {
            name: null,
            relationshipToClient: null,
            mobileNo: null,
            homeNo: null,
            officeNo: null,
          },
          personTwo: {
            name: null,
            relationshipToClient: null,
            mobileNo: null,
            homeNo: null,
            officeNo: null,
          }
        },
        sharingInformation: {
          caregiverName: null,
          caregiverNRICNo: null,
          relationship: null,
          clientName: null,
        }
      },
      consentFormInput: {
        relationship: null,
        clientName: null,
      },
      finalInput: {
        signatureImg: null,
        contactNo: null,
        dateSignature: null,
      },
      modalCara: "modalCara",   
    }
  },
  methods: {
    modalCancelActivity({id}){
      if(id === this.modalListId[0]){
        this.dismissModal(0);
        return;
      }
      if(id === this.modalListId[1]){
        this.cancelModalPopulate();
        return;
      }
    },
    modalConfirmActivity({id}){
      if(id === this.modalListId[0]){
        this.submitMidCaregiverRegistration();
        return;
      }
      if(id === this.modalListId[1]){
        this.populateData();
        return;
      }
    },
    callAlert() {
      const SECOND = 2;
      this.alert.dismissCountDown = SECOND;
    },
    constructAlertMessage(alertMessage, color = this.alert.colorOption[1]){
      this.alert.color = color;
      this.alert.alertMessage = alertMessage;
      this.callAlert();
    },
    cancelModalPopulate(){
      this.dismissModal(1);
    },
    openModal(modalIndex = 0) {
      this.$bvModal.show(this.modalListId[modalIndex]);
    },
    dismissModal(modalIndex = 0) {
      this.$bvModal.hide(this.modalListId[modalIndex]);
    },
    async getCaregiverData(isShowModal = true){
      try {

        if(!this.validateClientId()){
          // console.log('No client ID');
          return;
        }

        const request = await axios.post("/api/checkcaregiver", {
          _crb5c_client_value: this.clientId,
        });

        const caregiverData = request.data.result; 

        if(!caregiverData || caregiverData.length === 0){
          this.midRegistrationCaregiverState.isCaregiverInDB = false;
          // console.log('No Caregiver Data');
          this.tempCaregiverData = null;
          return;
        }

        this.tempCaregiverData = caregiverData;
        this.midRegistrationCaregiverState.isCaregiverInDB = true;

        await this.setGetModeDeliveryDataAndZaritData(this.clientId);

        if(isShowModal){
          this.openModal(1);
        }

      } catch (error) {
        console.error(error);
        const statusCode = error?.response?.status ?? '';
        this.constructAlertMessage(`${statusCode} Something went wrong...`, this.alert.colorOption[0]);
      }

    },
    async setGetModeDeliveryDataAndZaritData(clientId){
      await this.getModeDeliveryData(clientId);
      await this.getZaritData(clientId);

    },
    async getModeDeliveryData(clientId){
      try {

        const request = await axios.post("/api/checkmodedelivery", {
          _crb5c_client_value: clientId,
        });

        const modeDeliveryData = request.data.result; 

        // console.log('Mode Delivery Data: ');
        // console.log(modeDeliveryData);

        if(!modeDeliveryData || modeDeliveryData.length === 0){
          // console.log('No Mode Delivery Data');
          this.tempModeDeliveryData = null;
          return;
        }

        this.tempModeDeliveryData = modeDeliveryData;

      } catch (error) {
        console.error(error);
        const statusCode = error?.response?.status ?? '';
        this.constructAlertMessage(`${statusCode} Something went wrong...`, this.alert.colorOption[0]);
      }

    },
    async getZaritData(clientId){
      try {

        const request = await axios.post("/api/checkzarit", {
          _crb5c_client_value: clientId,
        });

        const zaritData = request.data.result; 

        // console.log('Mode Zarit Data: ');
        // console.log(zaritData);

        if(!zaritData || zaritData.length === 0){
          // console.log('No Zarit Data');
          this.tempZaritData = null;
          return;
        }

        this.tempZaritData = zaritData;

      } catch (error) {
        console.error(error);
        const statusCode = error?.response?.status ?? '';
        this.constructAlertMessage(`${statusCode} Something went wrong...`, this.alert.colorOption[0]);
      }
    },
    validateClientId(){
      return this.clientId && this.clientId !== '' && this.clientId.includes('-')
    },
    isClientLocalStorageFilled(){
      return localStorage.getItem('clientData') !== null;
    },
    getConditionIfThePrimaryCaregiverInputNotFilled(){
      return (
        this.caregiver.primary.fullName === "" &&
        this.caregiver.primary.email === "" &&
        this.caregiver.primary.contactMobile === "" &&
        this.caregiver.primary.relationship === "" &&
        // this.caregiver.primary.relationshipOthers === "" &&
        this.caregiver.primary.livingWithApplicant === null &&
        this.caregiver.primary.emergencyContact === null
      );

    },
    showBtnSaveDraftConditionIfThePrimaryCaregiverInputHasBeenFilled(val){
      return val.fullName !== "" &&
        val.email !== "" &&
        val.contactMobile !== "" &&
        val.relationship !== "" &&
        // val.relationshipOthers === "" &&
        val.livingWithApplicant !== null &&
        val.emergencyContact !== null
    },
    populateData(){

      if(this.tempCaregiverData.length === 1){
        const secondaryCaregiverData = {
          crb5c_caregiverrank: 1,
          crb5c_name: "",
          crb5c_contactnumberhome: "",
          crb5c_email: "",
          crb5c_contactnumbermobile: "",
          crb5c_relationship: "",
          // crb5c_relationship;
          crb5c_livingwithaplicant: null,
          crb5c_isthispersonemergencycontact: null,  
        }

        const otherCaregiverData = {
          crb5c_caregiverrank: 2,
          crb5c_name: "",
          crb5c_contactnumberhome: "",
          crb5c_email: "",
          crb5c_contactnumbermobile: "",
          crb5c_relationship: "",
          // crb5c_relationship;
          crb5c_livingwithaplicant: null,
          crb5c_isthispersonemergencycontact: null,  
        }

        this.tempCaregiverData.forEach((data)=>{
          this.setCaregiverData(data);
        });
        this.setCaregiverData(secondaryCaregiverData);
        this.setCaregiverData(otherCaregiverData);
      }

      if(this.tempCaregiverData.length === 2){
        const otherCaregiverData = {
          crb5c_caregiverrank: 2,
          crb5c_name: "",
          crb5c_contactnumberhome: "",
          crb5c_email: "",
          crb5c_contactnumbermobile: "",
          crb5c_relationship: "",
          // crb5c_relationship;
          crb5c_livingwithaplicant: null,
          crb5c_isthispersonemergencycontact: null,  
        }
        
        this.tempCaregiverData.forEach((data)=>{
          this.setCaregiverData(data);
        });
        this.setCaregiverData(otherCaregiverData);
      }

      if(this.tempCaregiverData.length === 3){
        this.tempCaregiverData.forEach((data)=>{
          this.setCaregiverData(data);
        });
      }


      if(this.tempModeDeliveryData){
        this.setModeDeliveryData(this.tempModeDeliveryData[0]);
      }else{
        const modeDeliveryData = {
          crb5c_modeofdelivery: "",
          crb5c_modeofdeliveryaddress: "",
        }
        this.setModeDeliveryData(modeDeliveryData, true);
      }

      if(this.tempZaritData){
        this.setZaritData(this.tempZaritData[0]);
      }else{
        const zaritData = {
          crb5c_zaritquestion1: "",
          crb5c_zaritquestion2: "",
          crb5c_zaritquestion3: "",
          crb5c_zaritquestion4: "",
        } 
        this.setZaritData(zaritData);
      }

      this.dismissModal(1);

    },
    setCaregiverData(caregiverData){

      if(caregiverData.crb5c_caregiverrank === this.caregiverRank[0]){
        this.caregiver.primary.fullName = caregiverData.crb5c_name;
        this.caregiver.primary.contactHome = caregiverData.crb5c_contactnumberhome;
        this.caregiver.primary.email = caregiverData.crb5c_email;
        this.caregiver.primary.contactMobile = caregiverData.crb5c_contactnumbermobile;
        this.caregiver.primary.relationship = caregiverData.crb5c_relationship;
        // this.caregiver.primary.relationshipOthers = caregiverData.crb5c_relationship;
        this.caregiver.primary.livingWithApplicant = caregiverData.crb5c_livingwithaplicant;
        this.caregiver.primary.emergencyContact = caregiverData.crb5c_isthispersonemergencycontact;
      }

      if(caregiverData.crb5c_caregiverrank === this.caregiverRank[1]){
        this.caregiver.secondaryFullName = caregiverData.crb5c_name;
        this.caregiver.secondaryContactHome = caregiverData.crb5c_contactnumberhome;
        this.caregiver.secondaryEmail = caregiverData.crb5c_email;
        this.caregiver.secondaryContactMobile = caregiverData.crb5c_contactnumbermobile;
        this.caregiver.secondaryRelationship = caregiverData.crb5c_relationship;
        // this.caregiver.secondaryrelationshipOthers = caregiverData.crb5c_relationship;
        this.caregiver.secondaryLivingWithApplicant = caregiverData.crb5c_livingwithaplicant;
        this.caregiver.secondaryEmergencyContact = caregiverData.crb5c_isthispersonemergencycontact;
      }

      if(caregiverData.crb5c_caregiverrank === this.caregiverRank[2]){
        this.caregiver.otherFullName = caregiverData.crb5c_name;
        this.caregiver.otherContactHome = caregiverData.crb5c_contactnumberhome;
        this.caregiver.otherEmail = caregiverData.crb5c_email;
        this.caregiver.otherContactMobile = caregiverData.crb5c_contactnumbermobile;
        this.caregiver.otherRelationship = caregiverData.crb5c_relationship;
        // this.caregiver.otherrelationshipOthers = caregiverData.crb5c_relationship;
        this.caregiver.otherLivingWithApplicant = caregiverData.crb5c_livingwithaplicant;
        this.caregiver.otherEmergencyContact = caregiverData.crb5c_isthispersonemergencycontact;
      }
    },
    setModeDeliveryData(modeDeliveryData, isNotInDB = false){
      if(isNotInDB){
        this.caregiver.recipient = null;
      }else{
        this.caregiver.recipient = 0;
      }

      this.caregiver.modeOfDelivery = modeDeliveryData.crb5c_modeofdelivery;
      this.caregiver.modeOfDeliveryAddress = modeDeliveryData.crb5c_modeofdeliveryaddress;

    },
    setZaritData(zaritData){
      this.caregiver.zaritQuestionOne = zaritData.crb5c_zaritquestion1;
      this.caregiver.zaritQuestionTwo = zaritData.crb5c_zaritquestion2;
      this.caregiver.zaritQuestionThree = zaritData.crb5c_zaritquestion3;
      this.caregiver.zaritQuestionFour = zaritData.crb5c_zaritquestion4;

    },
    async updateCaregiverMidRegistration(caregiverData, crb5c_fow_caregiverid){
      const payload = {
        ...this.$store.state.finalCaregiverData,
      }

      // eslint-disable-next-line no-unused-vars
      const { clientId, ...caregiversData } = payload;
      
      if(Object.keys(caregiverData).length === 0){
          // console.log('No data to submit mid registration');
          return;
      }

      const { order: crb5c_caregiverrank, ...rest } = caregiverData;

      const finalCaregiverData = {
        finalCaregivers: [{
          crb5c_caregiverrank,
          crb5c_fow_caregiverid,
          ...rest,
        }],
      };

      // console.log('Update Mid Registration Caregiver data: ');
      // console.log(finalCaregiverData);

      await axios.patch("/api/updatecaregivermidregistration", finalCaregiverData);

    },
    async createCaregievrMidRegistration(caregiverData){
      
      if(
        !this.validateClientId() && 
        Object.keys(caregiverData).length === 0){
        // console.log('No data to submit mid registration');
        return;
      }

      const { order: crb5c_caregiverrank, ...rest } = caregiverData;

      const finalCaregiverData = {
        clientId: this.clientId,
        finalCaregivers: [{
          crb5c_caregiverrank,
          ...rest,
        }],
      };

      // console.log('Create Mid Registration Caregiver data: ');
      // console.log(finalCaregiverData);

      await axios.post("/api/createcaregivermidregistration", finalCaregiverData);

    },
    async updateModeDeliveryMidRegistration(modeDeliveryId, dataModeDelivery){

      if(this.validateClientId() && !modeDeliveryId){
        // console.log('No clientID or ModeDeliveryId');
        return;
      }

      if(Object.keys(dataModeDelivery).length === 0){
        // console.log('No data Mode Delivery to submit mid registration');
        return;
      }

      const payload = {
        crb5c_caregiverclientmodeofdeliveryid: modeDeliveryId,
        ...dataModeDelivery
      };

      // console.log('Update Mid Registration Mode Delivery data: ');
      // console.log(payload);

      await axios.patch("/api/updatemodedeliverymidregistration", payload);

    },
    async createModeDeliveryMidRegistration(clientId, caregiverId, dataModeDelivery){
      
      if(this.validateClientId() && !caregiverId){
        // console.log('No clientID or caregiverID');
        return;
      }
      
      if(Object.keys(dataModeDelivery).length === 0){
        // console.log('No data Mode Delivery to submit mid registration');
        return;
      }

      const payload = {
        clientId,
        caregiverId,
        ...dataModeDelivery
      };

      // console.log('Create Mid Registration Mode Delivery data: ');
      // console.log(payload);

      await axios.post("/api/createmodedeliverymidregistration", payload);

    },
    async updateZaritMidRegistration(zaritId, dataZarit){
      if(this.validateClientId() && !zaritId){
        // console.log('No clientID or ZaritId');
        return;
      }

      if(Object.keys(dataZarit).length === 0){
        // console.log('No data Zarit to submit mid registration');
        return;
      }

      const payload = {
        crb5c_zaritscreenmeasureoffamilycaregiverburdenid: zaritId,
        ...dataZarit
      };

      // console.log('Update Mid Registration Mode Delivery data: ');
      // console.log(payload);

      await axios.patch("/api/updatezaritmidregistration", payload);

    },
    async createZaritMidRegistration(clientId, caregiverId, dataZarit){
      if(this.validateClientId() && !caregiverId){
        // console.log('No clientID or caregiverID');
        return;
      }
      
      if(Object.keys(dataZarit).length === 0){
        // console.log('No data Mode Delivery to submit mid registration');
        return;
      }

      const payload = {
        clientId,
        caregiverId,
        ...dataZarit
      };

      // console.log('Create Mid Registration Mode Delivery data: ');
      // console.log(payload);

      await axios.post("/api/createzaritmidregistration", payload);
    },
    destructuredTempCaregiverData(caregiverDataArray){
      return caregiverDataArray ? caregiverDataArray.map((data)=>{
          const { 
            crb5c_name, 
            crb5c_email, 
            crb5c_contactnumberhome, 
            crb5c_contactnumbermobile, 
            crb5c_relationship, 
            crb5c_livingwithaplicant, 
            crb5c_isthispersonemergencycontact,
            crb5c_caregiverrank,
            crb5c_fow_caregiverid,
          } = data;

          return {
            crb5c_name, 
            crb5c_email, 
            crb5c_contactnumberhome, 
            crb5c_contactnumbermobile, 
            crb5c_relationship, 
            crb5c_livingwithaplicant, 
            crb5c_isthispersonemergencycontact,
            crb5c_caregiverrank,
            crb5c_fow_caregiverid
          }
        }) : [];
    },
    async submitMidCaregiverRegistration(){
      try {

        this.isShowSpinner = true;

        const caregiverPayload = {
          finalCaregivers: this.$store.state.finalCaregiverData.finalCaregivers,
        }
        const modeDeliveryPayload = {
          ...this.$store.state.finalCaregiverData.primaryCaregiverData.delivery
        }
        const zaritPayload = {
          ...this.$store.state.finalCaregiverData.primaryCaregiverData.zarit
        }


        const modifyTempCaregiverData = this.destructuredTempCaregiverData(this.tempCaregiverData);

        // CAREGIVER
        // eslint-disable-next-line no-unused-vars
        for(let [index, data] of caregiverPayload.finalCaregivers.entries()){

          if(!modifyTempCaregiverData[index]){
            await this.createCaregievrMidRegistration(data);
          }else{
            // eslint-disable-next-line no-unused-vars
            const { crb5c_fow_caregiverid, ...rest } = modifyTempCaregiverData[index];
            await this.updateCaregiverMidRegistration(data, crb5c_fow_caregiverid);
          }
        }


        

        await this.getCaregiverData(false);
        const modifyTempTwoCaregiverData = this.destructuredTempCaregiverData(this.tempCaregiverData);
        const primaryCaregiver = modifyTempTwoCaregiverData[0];
        // MODE DELIVERY
        if(Object.values(modeDeliveryPayload).some(data => data !== '' && data !== null)){
          const { recipient: recipientIndex } = modeDeliveryPayload;
          const { crb5c_fow_caregiverid: caregiverId } = modifyTempTwoCaregiverData[recipientIndex];

          if(!this.tempModeDeliveryData){
            // const { crb5c_fow_caregiverid: caregiverId } = primaryCaregiver;
            await this.createModeDeliveryMidRegistration(this.clientId, caregiverId, modeDeliveryPayload);
          }
  
          if(this.tempModeDeliveryData){
            const { crb5c_caregiverclientmodeofdeliveryid: modeDeliveryId } = this.tempModeDeliveryData[0];
            modeDeliveryPayload["crb5c_fow_caregiverid"] = caregiverId;
            await this.updateModeDeliveryMidRegistration(modeDeliveryId, modeDeliveryPayload);
          }
        }

        
        // ZARIT
        if(Object.values(zaritPayload).some(data => data !== '' && data !== null)){
          if(!this.tempZaritData){
            const { crb5c_fow_caregiverid: caregiverId } = primaryCaregiver;
            await this.createZaritMidRegistration(this.clientId, caregiverId, zaritPayload);
          }
  
          if(this.tempZaritData){
            const { crb5c_zaritscreenmeasureoffamilycaregiverburdenid: zaritId } = this.tempZaritData[0];
            await this.updateZaritMidRegistration(zaritId, zaritPayload);
          }
        }


        // TODO: put dismiss modal and constructAlertMessage inside function create and update
        this.isShowSpinner = false;
        this.dismissModal(0);

        this.isShowSaveDraftCaregiver = false;
        this.isSubmitMidRegistration = true;
        this.constructAlertMessage(`Save caregiver draft successful!`, this.alert.colorOption[1]);

      } catch (error) {
        this.isShowSpinner = false;
        this.dismissModal(0);
        console.error(error);

        const statusCode = error?.response?.status ?? '';
        this.constructAlertMessage(`${statusCode} Something went wrong...`, this.alert.colorOption[0]);
      }
    },
    validateConsentForm(termAndConditionInput, consentFormInput, finalInput){
      try {
        const consentFormSchema = z.object({
          termAndConditionInput: z.object({
            caregiverName: z.string().min(1),
            clientName: z.string().min(1),
            clientNric: z.string().min(1),
            relationship: z.string().min(1),
            emergencyContact: z.object({
              personOne: z.object({
                name: z.string().nullable(),
                relationshipToClient: z.string().nullable(),
                mobileNo: z.string().nullable(),
                homeNo: z.string().nullable(),
                officeNo: z.string().nullable(),
              }),
              personTwo: z.object({
                name: z.string().nullable(),
                relationshipToClient: z.string().nullable(),
                mobileNo: z.string().nullable(),
                homeNo: z.string().nullable(),
                officeNo: z.string().nullable(),
              }),
            }),
            sharingInformation: z.object({
              caregiverName: z.string().min(1),
              caregiverNRICNo: z.string().min(1),
              relationship: z.string().min(1),
              clientName: z.string().min(1),
            })
          }),
          consentFormInput: z.object({
            relationship: z.string().min(1),
            clientName: z.string().min(1),
          }),
          finalInput: z.object({
            signatureImg: z.string().nullable(),
            contactNo: z.string().min(1),
            dateSignature: z.string().min(1),
          })
        }).strict();

        const payload = {
          termAndConditionInput,
          consentFormInput, 
          finalInput,
        }

        consentFormSchema.parse(payload);
        
        return true;

      } catch (error) {
        console.error(error);
        return false;

      }
    },
    clearSignature(){
      this.$refs.caregiverSignature.clear();
      this.finalInput.signatureImg = null;

    },
    //check input then show modal
    //then, let them choose yes or no for CARA, either choice will lead to submission of caregiver & client data to DB
    showModalAndCaraPermission(){
      const isSignatureEmpty = this.$refs.caregiverSignature.isEmpty();
        if(isSignatureEmpty){
          alert("Kindly sign the consent form before register");
          return;
        }

        const isConsentFormInputValid = this.validateConsentForm(this.termAndConditionInput, this.consentFormInput, this.finalInput);

        if(!isConsentFormInputValid){
          alert("Consent form is not complete");
          return;
        }
        this.$bvModal.show(this.modalCara);
    },

    async sendEmail(){
      if(!this.termAndConditionInput.clientName||!this.termAndConditionInput.clientNric||!this.termAndConditionInput.sharingInformation.caregiverName||!this.termAndConditionInput.sharingInformation.caregiverNRICNo||!this.caregiver.primary.contactMobile||!this.caregiver.primary.email){
        alert("Please complete the form before registration submission")
        return
      }else{
        try {
          const response = await axios.post('/api/sendcaraemail', {
            termAndConditionInput: this.termAndConditionInput,
            caregiver: this.caregiver,
          });

          // Assuming the server returns data in the response
          const result = response.data;
          console.log(result)
          alert("Registration submitted successfully! Please wait for our staff to review your application and contact you.");
        } catch (error) {
          // Handle errors
          console.error('Error sending email:', error);
          this.alert("Error submitting registration. Please try again.");
        }
      }
    },

    async RejectCaraAndSubmit(){
      try {
        this.submitting = true;
        this.closeModalCara()
        this.submit()
      } catch (error) {
        console.error("An error occurred during submission:", error);
      }
    },
    async AcceptCaraAndSubmit(){
      try {
        this.submitting = true;
        this.closeModalCara();
        await this.submit();
        this.sendEmail();
      } catch (error) {
        console.error("An error occurred during submission:", error);
      }
    },
    async submit() {
      try {
        this.finalInput.signatureImg = this.$refs.caregiverSignature.toDataURL().split(",")[1];

        const isSignatureEmpty = this.$refs.caregiverSignature.isEmpty();
        if(isSignatureEmpty){
          alert("Kindly sign the consent form before register");
          return;
        }

        const isConsentFormInputValid = this.validateConsentForm(this.termAndConditionInput, this.consentFormInput, this.finalInput);

        if(!isConsentFormInputValid){
          alert("Consent form is not complete");
          return;
        }
        
        this.submitting = true;
        this.$store.commit('finalClientDataCombine', {});

        const $ = axios.create({
          baseURL: window.location.host.toLowerCase().includes('localhost') ? 'http://localhost:3000' : window.location.origin
        })
        await $.post('/api/', {
            clientData: this.$store.state.clientData,
            caregiverData: this.$store.state.finalCaregiverData,
            //-- consent form
            termAndConditionInput: this.termAndConditionInput,
            consentFormInput: this.consentFormInput,
            finalInput: this.finalInput,
        });
        
        alert('Registration was successful! Please wait for our staff to review your application and contact you.');
        localStorage.removeItem('clientData');
        localStorage.removeItem('caregiverData');
        window.location.href = '/';
      } catch (e) {
        alert('Error: ' + e + '. Please try again.');
      }
    },
    closeModalCara(){
      this.$bvModal.hide(this.modalCara);
    },
  },
  computed: {
    caregiversData(){
      const finalObj = {};
      
      const primary = {
        crb5c_name: this.caregiver.primary.fullName,
        crb5c_email: this.caregiver.primary.email,
        crb5c_contactnumberhome: this.caregiver.primary.contactHome,
        crb5c_contactnumbermobile: this.caregiver.primary.contactMobile,
        crb5c_relationship: this.caregiver.primary.relationshipOthers ? this.caregiver.primary.relationshipOthers : this.caregiver.primary.relationship,
        crb5c_livingwithaplicant: this.caregiver.primary.livingWithApplicant,
        crb5c_isthispersonemergencycontact: this.caregiver.primary.emergencyContact,
        order: 0
      };
      const secondary = {
        crb5c_name: this.caregiver.secondaryFullName,
        crb5c_email: this.caregiver.secondaryEmail,
        crb5c_contactnumberhome: this.caregiver.secondaryContactHome,
        crb5c_contactnumbermobile: this.caregiver.secondaryContactMobile,
        crb5c_relationship: this.caregiver.secondaryRelationshipOthers ? this.caregiver.secondaryRelationshipOthers : this.caregiver.secondaryRelationship,
        crb5c_livingwithaplicant: this.caregiver.secondaryLivingWithApplicant,
        crb5c_isthispersonemergencycontact: this.caregiver.secondaryEmergencyContact,   
        order: 1,
      };
      const others = {
        crb5c_name: this.caregiver.otherFullName,
        crb5c_email: this.caregiver.otherEmail,
        crb5c_contactnumberhome: this.caregiver.otherContactHome,
        crb5c_contactnumbermobile: this.caregiver.otherContactMobile,
        crb5c_relationship: this.caregiver.otherRelationshipOthers ? this.caregiver.otherRelationshipOthers : this.caregiver.otherRelationship,
        crb5c_livingwithaplicant: this.caregiver.otherLivingWithApplicant,
        crb5c_isthispersonemergencycontact: this.caregiver.otherEmergencyContact,
        order: 2 
      };

      const finalCaregivers = [primary];

      if (secondary.crb5c_name) {
        finalCaregivers.push(secondary);
      }

      if (others.crb5c_name) {
        finalCaregivers.push(others)
      }

      finalObj.finalCaregivers = finalCaregivers;
      finalObj.primaryCaregiverData = {
        zarit: {
          crb5c_zaritquestion1: this.caregiver.zaritQuestionOne,
          crb5c_zaritquestion2: this.caregiver.zaritQuestionTwo,
          crb5c_zaritquestion3: this.caregiver.zaritQuestionThree,
          crb5c_zaritquestion4: this.caregiver.zaritQuestionFour
        },
        delivery: {
          crb5c_modeofdelivery: this.caregiver.modeOfDelivery,
          crb5c_modeofdeliveryaddress: this.caregiver.modeOfDeliveryAddress,
          recipient: this.caregiver.recipient
        }
      }
      return finalObj;
    },
    caregiversPass(){
      let caregiversPass = true;
      for (let c of this.caregiversData.finalCaregivers) {
        // console.log(c)
        if (c.crb5c_name && (c.crb5c_isthispersonemergencycontact === null || c.crb5c_livingwithaplicant === null)) {
          caregiversPass = false;
          break;
        }
      }

      return caregiversPass;
    },
    caregiversDataValid(){

      return !!(
        this.caregiver?.primary?.fullName?.trim() &&
        this.caregiversData.primaryCaregiverData.zarit.crb5c_zaritquestion1 &&
        this.caregiversData.primaryCaregiverData.zarit.crb5c_zaritquestion2 &&
        this.caregiversData.primaryCaregiverData.zarit.crb5c_zaritquestion3 &&
        this.caregiversData.primaryCaregiverData.zarit.crb5c_zaritquestion4 &&
        this.caregiver.recipient !== null && 
        this.caregiversPass &&
        (
          (this.caregiversData.primaryCaregiverData.delivery.crb5c_modeofdelivery  === 1 && this.caregiversData.primaryCaregiverData.delivery.crb5c_modeofdeliveryaddress) ||
          (this.caregiversData.primaryCaregiverData.delivery.crb5c_modeofdelivery === 0)
        )
      )
    },
    relationOptions() { 
      return [
        { text: "Select One", disabled: true, value: null },
        "Spouse",
        "Son",
        "Daughter",
        "Domestic Helper",
        "Relative",
        "Others",
      ]
    },
    modeOfTransportOptions() {
      return [
        {text: "Via Email", value: 0},
        {text: "By Hand", value: 1}
      ]
    },
    yesNoOptions() {
      return [
        { label: "Yes", value: true},
        {label: "No", value: false}
      ]
    },
    options() {
      return [
        { text: "Select One", value: null, disabled: true },
        "one",
        "two",
        "three",
      ]
    },
    zaritOptions() {
      return [
        { text: "Never 从不 觉得", value: 0},
        { text: "Rarely 很少 觉得", value: 1},
        { text: "Quite Frequently 经常 觉得", value: 2},
        { text: "Nearly Always 总 是 觉 得", value: 3}
      ]
    }
  },
   async mounted() {
    const cld = localStorage.getItem('clientData');
    if (cld) {
      const moddedCd = JSON.parse(cld);
      moddedCd.personalInfo.profilePic = null;
      this.$set(this, 'client', moddedCd);
      this.$store.commit('clientDataChange', moddedCd);

      this.clientId = moddedCd.clientId;
      this.termAndConditionInput.clientNric = moddedCd.personalInfo.crb5c_nricno;

      // set client data for consent form
      this.termAndConditionInput.clientName = moddedCd.personalInfo.crb5c_no;
      this.termAndConditionInput.sharingInformation.clientName = moddedCd.personalInfo.crb5c_no;
      this.consentFormInput.clientName = moddedCd.personalInfo.crb5c_no;

    }

    const cd = localStorage.getItem('caregiverData');
    if (cd) {
      const moddedCd = JSON.parse(cd);
      this.$set(this, 'caregiver', moddedCd);
    }

    await this.getCaregiverData();
    
    // set data for consent form
    this.finalInput.dateSignature = dayjs().format("YYYY-MM-DD");
  },
  watch: {
    caregiver: {
      handler(val) {
        if(this.showBtnSaveDraftConditionIfThePrimaryCaregiverInputHasBeenFilled(val.primary) && this.validateClientId()){
          this.isShowSaveDraftCaregiver = true;
        }else{
          this.isShowSaveDraftCaregiver = false;
        }

        if(this.isSubmitMidRegistration){
          this.isShowSaveDraftCaregiver = false;
        }

        this.$store.commit('caregiverDataChange', val);

        // set data for consent form
        // set caregiver
        this.termAndConditionInput.relationship = val.primary.relationship;
        this.termAndConditionInput.sharingInformation.relationship = val.primary.relationship;
        this.consentFormInput.relationship = val.primary.relationship;
        this.termAndConditionInput.caregiverName = val.primary.fullName;
        this.termAndConditionInput.sharingInformation.caregiverName = val.primary.fullName;
        this.finalInput.contactNo = val.primary.contactMobile;

      },
      deep: true
    },
    caregiversData: {
      handler(val) {
        this.$store.commit('finalCaregiverDataChange', val);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #4b2b67;


.margin-container {
  margin: 0 12%;
}
@media only screen and (max-width: 768px) {
  .margin-container {
    margin: 0 8%;
  }
}

@media only screen and (max-width: 480px) {
  .margin-container {
    margin: 0 4%;
  }
}
.btn-info {
  color: white !important;
}

.optionYesBtn{
  width: 20vh;
  background: #794fa1;
  color: white;
  border: none;
}

.optionCancelBtn{
  width: 20vh;
  background: #bd291e;
  color: white;
  border: none;
}
.topNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .RightSide {
    display: flex;
    align-items: center;
    img {
      width: fit-content;
      height: fit-content;
    }
    width: 10px;

    h2 {
      margin: 5px;
      white-space: nowrap;
      color: $primary;
      margin-left: 30px;
    }
  }
  .LeftSide {
    button {
      background-color: $primary;
      margin: 10px;
      padding: 1em;
      color: white;
      border: none;
      border-radius: 0.4em;
      cursor: pointer;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.container {
  width: 60%;
}
label {
  width: 100%;
  text-align: start;
}
.select {
  width: 100%;
  height: 35px;
}
.middle {
  align-items: center;
}
#fixedButton {
  position: fixed;
  top: 90vh;
  left: 82%;

  background-color: $primary;
  color: white;
}
</style>
