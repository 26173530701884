import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clientData: {},
    caregiverData: {},
    finalCaregiverData: {}
  },
  mutations: {
    clientDataChange(state, payload) {
      state.clientData = payload;
      localStorage.setItem('clientData', JSON.stringify(payload));
    },
    caregiverDataChange(state, payload) {
      state.caregiverData = payload;
      localStorage.setItem('caregiverData', JSON.stringify(payload));
    },
    finalCaregiverDataChange(state, payload) {
      state.finalCaregiverData = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
