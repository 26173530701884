import Vue from "vue";
import VueRouter from "vue-router";
import FormCaregiver from "../view/FormCaregiver";
import ClientForm from "../view/ClientForm";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ClientForm,
  },
  {
    path: "/caregiverForm",
    name: "CareGiver Form",
    component: FormCaregiver,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
