<template lang="pug">
div
    b-modal(:id="id" size="sm" :title="title" centered)
        template
            p
                | {{ message }}
        template(#modal-footer)
            b-spinner(v-if="isShowSpinner" variant="success")
            b-button(size="sm" variant="success" @click="emitConfirm")
                | {{ btnOkText }}
            b-button(size="sm" variant="outline-secondary" @click="emitCancel")
                | Cancel
</template>

<script>
export default {
    name: "ModalConfirmation",
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        btnOkText: {
            type: String,
            required: true,
        },
        isShowSpinner: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        emitCancel(){
            this.$emit('modal-cancel', {
                id: this.id, 
            });
        },
        emitConfirm(){
            this.$emit('modal-confirm', {
                id: this.id,
            });
        },
    },
}
</script>

<style lang="">
    
</style>