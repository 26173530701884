<template lang="pug">
div.px-3.py-2
        div.d-flex.justify-content-between.align-items-center
            img(src="../assets/logo.png")
            router-link(v-if="$route.path !== '/'" to="/") 
              b-button.bg-primary.text-white() Back
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>
<style lang="scss" scoped>
$primary: #4b2b67;

img {
  width: fit-content;
  height: fit-content;
}

</style>
